<template>
	<div class="home">
		<myheader
			style="
				border-bottom: solid 1px #e6e6e6;
				box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);
			"
		></myheader>
		<el-container style="height: calc(100% - 60px)">
			<menus></menus>
			<el-main>
				<div class="box-conent" style="background: #fff">
					<router-view></router-view>
				</div>
				<p
					style="
						height: 40px;
						text-align: center;
						line-height: 40px;
						color: rgba(0, 0, 0, 0.45);
					"
				>
					Copyright@2022 深圳市悦融易数据科技有限公司版权所有
				</p>
			</el-main>
		</el-container>
		<notice
			@close="
				() => {
					centerDialogVisible = false;
					changeProtocolIdentification();
				}
			"
			:centerDialogVisible.sync="centerDialogVisible"
		/>
	</div>
</template>

<script>
import { changeProtocolIdentification } from '@/api/index.js';
import notice from '../index/model/notice.vue';
import myheader from '../../components/myheader/myheader.vue';
import menus from '../../components/menus/menus.vue';
export default {
	name: 'home',
	data() {
		return {
			time: null,
			centerDialogVisible: false,
		};
	},
	methods: {
		async changeProtocolIdentification() {
			await changeProtocolIdentification();
			localStorage.setItem('read', 'true');
		},
	},
	created() {
		this.$store.dispatch('getsystemMessageNumber');

		this.time = setInterval(() => {
			this.$store.dispatch('getsystemMessageNumber');
		}, 100000);
	},
	components: {
		myheader,
		menus,
		notice,
	},
	beforeDestroy() {
		clearInterval(this.time);
	},
	mounted() {
		if (!localStorage.getItem('read')) {
			if (this.$store.state.userInfo.userVo) {
				this.centerDialogVisible = this.$store.state.userInfo.userVo
					.protocolIdentification
					? false
					: true;
			}
		}
	},
};
</script>
<style lang="scss">
.home {
	width: 100%;
	height: 100%;
}
.el-header,
.el-footer {
	background-color: #fff;
	line-height: 60px;
	text-align: center;
	color: #333;
}
.el-header {
	display: flex;
	justify-content: space-between;
	align-content: center;
}
.box-conent > div {
	min-height: calc(100vh - 125px);
}
.el-main {
	padding: 25px 25px 0 25px !important;
	background: #f0f2f5;
}
.el-footer {
	margin-top: 10px;
	background: #f0f2f5;
	line-height: 30px;
}
</style>
