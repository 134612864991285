<template>
	<el-header>
		<div style="display: flex; align-items: center">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<img src="../../assets/yrylogo.png" style="width: 164px; height: 32px; margin-right: 30px" alt="" />
				<el-menu v-show="false" :default-active="`/${$route.path.split('/')[1]}`" class="el-menu-vertical-demo"
					@open="handleOpen" @close="handleClose" unique-opened router mode="horizontal" v-if="asyncRouter.length">
					<el-menu-item v-for="(item, index) in asyncRouter[0].children" :key="index" :index="item.path">
						<i class="el-icon-menu"></i>
						<span slot="title">{{ item.meta.name }}</span>
					</el-menu-item>
				</el-menu>
			</el-breadcrumb>
		</div>
		<div style="display: flex; align-items: center">
			<div @click="$router.push('/index/notification')">
				<el-badge :value="this.$store.state.massageunm" :max="99" class="item"
					:hidden="this.$store.state.massageunm ? false : true">
					<i style="
							font-size: 23px;
							margin-right: 20px;
							cursor: pointer;
						" class="el-icon-arrow-down el-icon-bell"></i>
				</el-badge>
			</div>

			<div class="userinfo">
				<el-dropdown>
					<span class="el-dropdown-link">
						<div style="
								width: 40px;
								height: 40px;
								border-radius: 50%;
								background: #53abff;
								color: #fff;
								overflow: hidden;
								font-weight: 800;
								line-height: 40px;
								text-align: center;
							">
							{{
								userInfo.userVo.alias.length > 2
								? userInfo.userVo.alias.slice(1)
								: userInfo.userVo.alias
							}}
						</div>
						<i class="el-icon-arrow-down el-icon--right"></i>
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item @click.native="$router.push('/index/myinfo')">我的信息</el-dropdown-item>
						<el-dropdown-item @click.native="
							$router.push('/index/changepassword')
							">修改密码</el-dropdown-item>
						<el-dropdown-item @click.native="outlogin">退出登录</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>
	</el-header>
</template>

<script>
import { mapState } from 'vuex';

export default {
	name: 'myheader',
	data() {
		return {
			activeIndex: '1',
			activeIndex2: '1',
		};
	},
	methods: {
		outlogin() {
			localStorage.removeItem('read');
			localStorage.removeItem('asyncRouter');
			localStorage.removeItem('userInfo');
			localStorage.removeItem('mapList');
			this.$router.replace('/login');
		},
		handleSelect(key, keyPath) {
			console.log(key, keyPath);
		},
		handleOpen(key, keyPath) {
			console.log(key, keyPath);
		},
		handleClose(key, keyPath) {
			console.log(key, keyPath);
		},
	},
	created() { },
	computed: {
		...mapState(['asyncRouter', 'userInfo']),
	},
};
</script>
<style lang="scss" scoped>
// 面包屑
.el-breadcrumb {
	display: flex;
	align-items: center;
}

// 下拉菜单
.el-dropdown-link {
	cursor: pointer;
	color: #409eff;
	display: flex;
	align-items: center;
	justify-content: center;
}

.el-icon-arrow-down {
	font-size: 12px;
}

.item {
	margin-top: 10px;
	margin-right: 0px;
}

::v-deep .el-badge__content.is-fixed {
	top: -16px !important;
	right: 29px !important;
	transform: scale(0.8);
	position: relative;
}
</style>
