<template>
	<div style="position: relative">
		<el-menu
			class="el-menu-vertical-demo"
			@open="handleOpen"
			@close="handleClose"
			:collapse="$store.state.isCollapse"
			unique-opened
			router
			:default-active="$route.path"
			v-if="showChildrenAsyncRouter.length"
		>
			<el-menu-item
				v-for="(item, index) in showChildrenAsyncRouter"
				:key="index"
				:index="item.path"
			>
				<i
					style="margin-right: 5px; font-size: 14px"
					:class="['icon', 'iconfont', item.meta.icon]"
				></i>
				<span slot="title">{{ item.meta.name }}</span>
			</el-menu-item>
		</el-menu>

		<i
			style="
				margin-right: 10px;
				font-size: 26px;
				position: absolute;
				right: 0;
				bottom: 15px;
			"
			:class="{
				'el-icon-s-fold': !$store.state.isCollapse,
				'el-icon-s-unfold': $store.state.isCollapse,
			}"
			@click="changeisCollaps"
		></i>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'menus',
	data() {
		return {};
	},
	methods: {
		handleOpen(key, keyPath) {
			console.log(key, keyPath);
		},
		handleClose(key, keyPath) {
			console.log(key, keyPath);
		},
		changeisCollaps() {
			this.$store.commit('menuitem', !this.$store.state.isCollapse);
		},
	},
	computed: {
		...mapState(['asyncRouter']),
		childrenAsyncRouter() {
			return this.asyncRouter[0].children.filter(
				(item) => item.path == `/${this.$route.path.split('/')[1]}`
			);
		},
		showChildrenAsyncRouter() {
			return this.childrenAsyncRouter[0].children.filter(
				(item) => item.meta.isShow
			);
		},
	},
	created() {},
};
</script>
<style lang="scss" scoped>
// 侧边栏导航
.el-menu-vertical-demo:not(.el-menu--collapse) {
	width: 200px;
	min-height: 400px;
}
.el-menu-item {
	height: 46px;
	line-height: 46px;
}
@media screen and (max-width: 1000px) {
	.el-menu {
		display: none;
	}
}
</style>
